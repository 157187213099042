import React from 'react';
import { graphql } from 'gatsby';
import QueryString from 'query-string';
import Articles from 'components/shared/Articles';
import { H1, H2, Wrapper } from 'styles/page';

function Search({ data, pageContext, location }) {
  const query = QueryString.parse(location.search).q || '';
  const { edges } = data.allMdx;
  const filteredPosts = edges.filter(({ node }) =>
    node.frontmatter.title.toLowerCase().includes(query.toLowerCase())
  );
  return (
    <Wrapper>
      <H1>Search results for:</H1>
      <H2>{query}</H2>
      {filteredPosts.length ? (
        <Articles
          posts={filteredPosts}
          pageContext={pageContext}
          showHeader={false}
          showPagination={false}
        />
      ) : (
        <Articles
          posts={[]}
          showHeader={false}
          showPagination={false}
          content={<p>Sorry, No results found</p>}
        />
      )}
    </Wrapper>
  );
}

export const query = graphql`
  {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            featuredImage {
              childImageSharp {
                fixed(width: 400) {
                  src
                }
              }
            }
            author
            date
            excerpt
            path
            title
            tags
          }
        }
      }
    }
  }
`;

export default Search;
